'use client'

import {
  motion,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from 'framer-motion'
import Image from 'next/image'
import { useRef, useState } from 'react'
import { useMedia, useMount } from 'react-use'

import { Notification1, Notification2, PhoneMock } from '../PhoneMock'
import { Text } from '../Text'

export interface FrontHeroProps {
  title?: string
}

export const FrontHero = ({ title }: FrontHeroProps) => {
  const [ready, setReady] = useState(false)
  const isWide = useMedia('(min-width: 768px)', true)

  const [notification1Open, setNotification1Open] = useState(false)
  const [notification2Open, setNotification2Open] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end end'],
  })

  const blur = useTransform(scrollYProgress, [0.3, 1], [0, 6])

  const opacityText = useTransform(scrollYProgress, [0.4, 0.8], [1, 0])
  const scaleText = useTransform(scrollYProgress, [0.6, 0.8], [1, 0.95])
  const filterText = useTransform(blur, (v) => `blur(${v.toFixed(4)}px`)
  const yPosPhone = useTransform(scrollYProgress, [0, 1], ['0%', '-50%'])

  useMount(() => {
    setReady(true)
  })

  useMotionValueEvent(scrollYProgress, 'change', (value) => {
    if (!drawerOpen) {
      if (value > 0.4) {
        setDrawerOpen(true)
      }
    } else {
      if (value <= 0.4) {
        setDrawerOpen(false)
      }
    }

    if (!notification1Open) {
      if (value > 0.2) {
        setNotification1Open(true)
      }
    } else {
      if (value <= 0.2) {
        setNotification1Open(false)
      }
    }

    if (!notification2Open) {
      if (value > 0.7) {
        setNotification2Open(true)
      }
    } else {
      if (value <= 0.7) {
        setNotification2Open(false)
      }
    }
  })

  return (
    <motion.div
      ref={containerRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: ready ? 1 : 0 }}
      className="w-full min-h-[250svh] inline-block md:-top-[70px]"
    >
      <motion.div className="sticky top-0 h-[100svh] min-w-[100vw] min-h-0 md:min-h-[1400px] 2xl:min-h-[1200px] overflow-hidden">
        <motion.div className="absolute aspect-gradial bg-gray-1 flex justify-center items-center w-full max-w-[1200px] min-w-[800px] left-1/2 -translate-x-1/2 top-[60%] -translate-y-1/2 bottom-auto right-auto">
          <Image src="/misc/gradial.jpg" fill alt="" />
        </motion.div>
        <div className="relative pt-80 md:pt-48 2xl:mt-32 inline-flex flex-col justify-center items-center min-w-[100vw] h-full overflow-hidden w-full">
          <motion.div
            initial={{
              y: -50,
            }}
            animate={{
              y: 0,
            }}
            transition={{
              duration: 1,
              ease: 'circOut',
            }}
          >
            <motion.div
              style={{
                scale: scaleText,
                opacity: opacityText,
                filter: filterText,
              }}
              className="max-w-[630px] md:max-w-[830px] mx-auto px-6"
            >
              <Text variant="h2" as="h1" className="text-center">
                {title}
              </Text>
            </motion.div>
          </motion.div>
          <motion.div
            style={{
              y: yPosPhone,
            }}
            className="w-full mx-auto flex justify-center items-center relative my-24"
          >
            <motion.div
              initial={{
                y: 100,
              }}
              animate={{
                y: 0,
              }}
              transition={{
                duration: 1,
                ease: 'circOut',
              }}
              className="relative w-[226px] md:w-[326px]"
            >
              <div className="relative">
                <Image src="/misc/drawer.png" fill alt="" />
              </div>
              <PhoneMock width="100%" drawerOpen={drawerOpen} />
            </motion.div>
            <motion.div
              initial={{
                x: -100,
                opacity: 0,
              }}
              animate={{
                x: notification1Open ? (isWide ? '50%' : 30) : 0,
                opacity: notification1Open ? 1 : 0,
              }}
              transition={{
                duration: 0.4,
                ease: 'easeInOut',
              }}
              className="absolute top-20 md:top-32 w-[323px]"
            >
              <Notification1 height={isWide ? 120 : 100} />
              {/* <Image
                src="/phone/notification.png"
                width={375}
                quality={100}
                height={118}
                alt=""
                className="transform scale-75 md:scale-100"
              /> */}
            </motion.div>
            <motion.div
              initial={{
                x: -100,
                opacity: 0,
              }}
              animate={{
                x: notification2Open ? (isWide ? '-50%' : -30) : 0,
                opacity: notification2Open ? 1 : 0,
              }}
              transition={{
                duration: 0.4,
                ease: 'easeInOut',
              }}
              className="absolute top-56 md:top-72 w-[323px]"
            >
              <Notification2 height={isWide ? 120 : 100} />
              {/* <Image
                src="/phone/notification2.png"
                width={226}
                quality={100}
                height={105}
                alt=""
                className="transform scale-75 md:scale-100"
              /> */}
            </motion.div>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default FrontHero
