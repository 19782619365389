import { EmployeesProps } from './Employees'

export const employees: EmployeesProps['items'] = [
  {
    fullName: 'Arnaldur Friðgeir Axfjörð',
    role: 'Sérfræðingur',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
    group: 'Flokkur 1',
  },
  {
    fullName: 'Ásdís Svava Hallgrímsdóttir',
    role: 'Gæðasérfræðingur',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Ásmundur Sveinsson',
    role: 'Kerfisstjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Ásta Heiðrún Gylfadóttir',
    role: 'Verkefnastjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Berglind Ragnarsdóttir',
    role: 'Vörustjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Björn Þorvarðarson',
    role: 'Forritari',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Einar Ársæll Hrafnsson',
    role: 'Kerfisstjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Einar Gunnlaugsson',
    role: 'Kerfisstjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
    group: 'Flokkur 1',
  },
  {
    fullName: 'Erla Valgerður Birgisdóttir',
    role: 'Þjónustufulltrúi',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Erna Birgisdóttir',
    role: 'Lögfræðingur',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Haraldur A. Bjarnason',
    role: 'Framkvæmdastjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Ingibjörg Erna Jónsdóttir',
    role: 'Lögfræðingur',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
    group: 'Flokkur 1',
  },
  {
    fullName: 'Írena Rut Jónsdóttir',
    role: 'Þjónustufulltrúi',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Jakob Ludwig Philip',
    role: 'Kerfisstjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
    group: 'Flokkur 2',
  },
  {
    fullName: 'Jenný Huyen Andradóttir',
    role: 'Verkefnastjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
    group: 'Flokkur 1',
  },
  {
    fullName: 'Jónas Sturla Sveinsson',
    role: 'Öryggisstjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Kolbrún Klara Gísladóttir',
    role: 'Kerfisstjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
    group: 'Flokkur 2',
  },
  {
    fullName: 'Kristín Lind Steingrimsdóttir',
    role: 'Upplýsingamiðlun',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Óli Halldór Konráðsson',
    role: 'Sérfræðingur',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Roland Nóason',
    role: 'Kerfisstjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Sigmar Stefánsson',
    role: 'Hugbúnaðarhönnuður',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Sigríður Sveinsdóttir',
    role: 'Verkefnastjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
    group: 'Flokkur 2',
  },
  {
    fullName: 'Snorri Vignisson',
    role: 'Vörustjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Sveinbjörn Grétarsson',
    role: 'Þjónustustjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Sverrir B. Sverrisson',
    role: 'Tæknistjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Tanja Kristín Leifsdóttir',
    role: 'Sérfræðingur',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Vala Kristín Theodórsdóttir',
    role: 'Þjónustufulltrúi',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Zaki Ögmundsson',
    role: 'Verkefnastjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Þórður Roth',
    role: 'Vörustjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Þórður Magnússon',
    role: 'Kerfisstjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
  },
  {
    fullName: 'Þórunn Stefánsdóttir',
    role: 'Fjármála- og mannauðsstjóri',
    email: 'hello@hi.is',
    image: {
      src: 'https://via.placeholder.com/172',
      alt: '',
    },
    group: 'Flokkur 1',
  },
]
