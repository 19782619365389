'use client'

import { motion } from 'framer-motion'
import { ArrowRight } from 'lucide-react'
import { ReactNode, useState } from 'react'

import { Label } from '../Label'
import { Link } from '../Link'
import { Text } from '../Text'

export interface NewsLinkProps {
  url: string
  children: ReactNode
  tag?: string
}

export const NewsLink = ({ children, url, tag }: NewsLinkProps) => {
  const [hovering, setHovering] = useState(false)

  return (
    <Link
      href={url}
      onBlur={() => setHovering(false)}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <motion.div
        initial={{
          borderColor: 'var(--color-baby-blue)',
        }}
        animate={
          hovering
            ? {
                borderColor: 'var(--color-blue)',
              }
            : {}
        }
        transition={{
          duration: 0.3,
        }}
        className="bg-white border border-1 border-collapse text-blue rounded-16px p-8 inline-flex flex-col gap-4 w-full"
      >
        {tag && (
          <div>
            <Label>{tag}</Label>
          </div>
        )}
        <Text variant="cardTitle" as="h3">
          {children}
        </Text>
        <div className="flex items-center w-full gap-4">
          <div className="shrink-0">
            <motion.div
              initial={{
                rotate: 90,
                backgroundColor: 'var(--color-white)',
                color: 'var(--color-dark)',
              }}
              animate={
                hovering
                  ? {
                      rotate: 0,
                      backgroundColor: 'var(--color-blue)',
                      color: 'var(--color-white)',
                    }
                  : {}
              }
              transition={{
                duration: 0.3,
              }}
              className="inline-flex rounded-full w-[25px] h-[25px] items-center justify-center"
            >
              <ArrowRight width={14} />
            </motion.div>
          </div>
        </div>
      </motion.div>
    </Link>
  )
}

export default NewsLink
