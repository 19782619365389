'use client'

import { motion } from 'framer-motion'
import { ReactNode } from 'react'

export interface SectionInViewProps {
  children?: ReactNode
  className?: string
  amount?: number
  name?: string
}

export const SectionInView = ({
  children,
  amount = 0.2,
  name,
  ...props
}: SectionInViewProps) => {
  return (
    <motion.section
      data-section-name={name}
      initial={{
        scale: 0.95,
        opacity: 0,
      }}
      whileInView={{
        scale: 1,
        opacity: 1,
      }}
      viewport={{
        amount,
        once: true,
      }}
      transition={{
        duration: 0.6,
        ease: 'easeOut',
        bounce: 0,
      }}
      {...props}
    >
      {children}
    </motion.section>
  )
}

export default SectionInView
