'use client'

import { motion } from 'framer-motion'
import { ReactNode } from 'react'

import { Button } from '../Button/Button'
import { Label } from '../Label'
import { Link } from '../Link'
import { Text } from '../Text'

import { cn } from '@/lib/utils'

export interface CenterCtaProps {
  label?: string
  heading: string
  text?: string
  buttonText?: string
  href?: string
  children?: ReactNode
}

export const CenterCta = ({
  label,
  heading,
  text,
  buttonText,
  href,
  children,
}: CenterCtaProps) => {
  return (
    <div
      className={cn(
        'bg-baby-blue w-full flex flex-col items-center justify-center relative px-10',
        {
          'py-48': !children,
          'pt-48 pb-32': children,
        },
      )}
    >
      <div className="absolute top-0 right-0 bottom-0 left-0 overflow-hidden flex items-center justify-center">
        <svg
          width="100%"
          height="100%"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Circle delay={0} radius={400} />
          <Circle delay={1.5} radius={460} />
          <Circle delay={3} radius={520} />
        </svg>
      </div>
      <div className="flex items-center justify-center flex-col max-w-[500px] text-center relative space-y-6">
        {label && <Label variant="white">{label}</Label>}
        <Text variant="h3">{heading}</Text>
        {text && (
          <div className="max-w-[400px] text-gray-4">
            <Text variant="pSmall">{text}</Text>
          </div>
        )}
        {href && buttonText && (
          <Button as={Link} icon="arrow-up-right" variant="white" href={href}>
            {buttonText}
          </Button>
        )}
      </div>
      {!!children && (
        <div className="mt-8 md:mt-16 w-full flex justify-center">
          {children}
        </div>
      )}
    </div>
  )
}

export default CenterCta

type CircleProps = {
  delay: number
  radius: number
}

const Circle = ({ delay, radius }: CircleProps) => {
  return (
    <motion.circle
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1,
        delay,
        repeat: Infinity,
        repeatType: 'reverse',
      }}
      cx="50%"
      cy="50%"
      r={radius}
      stroke="white"
    />
  )
}
