import { groq } from 'next-sanity'

import { client } from '@/sanity/lib/client'
import { NewsItemsQueryResult } from '@/types/general'

const query = groq`{
  "items": *[
    _type in ["news"]
  ][$start...$end] {
    _id,
    title,
    slug,
    seo,
    _type
  },
  "total": count(*[
    _type in ["news"]
  ]),
}`

export type GetNewsItems = {
  start: number
  end: number
}

export const getNewsItems = async ({
  start = 0,
  end = 6,
}: GetNewsItems): Promise<NewsItemsQueryResult | null> => {
  let results

  try {
    results = await client().fetch<NewsItemsQueryResult>(query, {
      start,
      end,
    })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }

  return results ? results : null
}
