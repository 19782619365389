import { motion } from 'framer-motion'
import * as React from 'react'
import { SVGProps } from 'react'

type PhoneMockProps = SVGProps<SVGSVGElement> & { drawerOpen?: boolean }

export const PhoneMock = ({ width, drawerOpen, ...props }: PhoneMockProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? 326}
    viewBox="0 0 326 677"
    fill="none"
    className="relative"
    {...props}
  >
    <rect width={325.934} height={676.021} fill="#F8F8FB" rx={54.027} />
    <rect
      width={325.132}
      height={675.22}
      x={0.401}
      y={0.401}
      stroke="url(#phone-mock-a)"
      strokeOpacity={0.2}
      strokeWidth={0.801}
      rx={53.627}
    />
    <g clipPath="url(#phone-mock-b)">
      <rect
        width={300.418}
        height={650.506}
        x={12.758}
        y={12.758}
        fill="#fff"
        rx={40.824}
      />
      <rect
        width={300.418}
        height={650.506}
        x={12.758}
        y={12.758}
        fill="#F0F2FC"
        fillOpacity={0.05}
        rx={40.824}
      />
      <rect
        width={300.418}
        height={650.506}
        x={12.758}
        y={12.758}
        fill="url(#phone-mock-c)"
        fillOpacity={0.2}
        rx={40.824}
      />
      <path
        fill="#0F1108"
        d="M56.124 30.39c.49 0 .956.09 1.395.272.44.178.829.457 1.167.838.342.376.61.86.805 1.452.195.587.292 1.295.292 2.124v.013c0 1.027-.148 1.906-.444 2.638-.296.731-.72 1.293-1.274 1.686-.55.39-1.207.584-1.973.584-.562 0-1.07-.102-1.521-.304a3.114 3.114 0 0 1-1.117-.85 2.968 2.968 0 0 1-.59-1.243l-.012-.07h1.604l.026.063a1.585 1.585 0 0 0 .888.933c.215.084.456.127.722.127.482 0 .876-.142 1.18-.425.309-.288.539-.67.691-1.148a5.847 5.847 0 0 0 .266-1.598c.005-.064.007-.125.007-.184v-.184l-.286-1.528c0-.347-.08-.662-.24-.945a1.812 1.812 0 0 0-.654-.672 1.732 1.732 0 0 0-.92-.248c-.329 0-.631.08-.906.241a1.826 1.826 0 0 0-.907 1.586v.012c0 .355.078.67.235.945.156.27.37.484.64.64.27.157.58.235.926.235.347 0 .657-.076.932-.228.275-.152.493-.364.653-.634.161-.27.241-.577.241-.92v-.012h.38v1.687h-.221a2.22 2.22 0 0 1-.488.672 2.45 2.45 0 0 1-.8.5c-.312.127-.68.191-1.103.191-.583 0-1.099-.131-1.547-.393a2.849 2.849 0 0 1-1.059-1.078 3.131 3.131 0 0 1-.38-1.547v-.013c0-.622.143-1.173.43-1.655a3.072 3.072 0 0 1 1.206-1.142c.515-.279 1.1-.418 1.756-.418Zm6.538 8.148a.993.993 0 0 1-.723-.285.977.977 0 0 1-.285-.71c0-.283.095-.52.285-.71a.982.982 0 0 1 .723-.292c.292 0 .533.097.723.292.19.19.285.427.285.71a.978.978 0 0 1-.285.71.98.98 0 0 1-.723.285Zm0-4.705a.993.993 0 0 1-.723-.285.977.977 0 0 1-.285-.71c0-.284.095-.52.285-.71a.981.981 0 0 1 .723-.292c.292 0 .533.097.723.291.19.19.285.427.285.71a.978.978 0 0 1-.285.71.98.98 0 0 1-.723.286Zm7.318 5.936v-1.757h-4.477v-1.338c.24-.41.482-.822.723-1.237a120.285 120.285 0 0 1 1.496-2.46c.254-.406.506-.805.755-1.198.254-.398.507-.785.76-1.16h2.303v6.042h1.236v1.351H71.54v1.757h-1.56Zm-2.955-3.07h2.98v-4.775h-.095c-.186.288-.378.584-.577.888a90.705 90.705 0 0 0-1.18 1.89 71.618 71.618 0 0 0-1.128 1.902v.095Zm9.537 3.07v-7.54h-.114l-2.277 1.604v-1.535l2.391-1.68h1.63v9.15h-1.63Z"
      />
      <rect
        width={18.335}
        height={9.167}
        x={267.533}
        y={30.712}
        stroke="#0F1108"
        strokeWidth={0.764}
        opacity={0.35}
        rx={2.903}
      />
      <path
        fill="#0F1108"
        d="M287.013 33.982v3.114a1.69 1.69 0 0 0 1.015-1.557c0-.68-.4-1.293-1.015-1.557Z"
        opacity={0.4}
      />
      <rect
        width={16.043}
        height={6.875}
        x={268.678}
        y={31.858}
        fill="#0F1108"
        rx={1.91}
      />
      <path
        fill="#0F1108"
        fillRule="evenodd"
        d="M255.134 32.699c1.9 0 3.727.705 5.104 1.968.104.097.27.096.372-.003l.991-.965a.26.26 0 0 0-.002-.377c-3.614-3.343-9.316-3.343-12.931 0a.26.26 0 0 0-.002.377l.991.965c.102.1.268.1.372.003a7.556 7.556 0 0 1 5.105-1.968Zm-.003 3.224c1.037 0 2.037.39 2.806 1.097.104.1.267.098.369-.005l.983-1.008a.279.279 0 0 0-.004-.396 6.035 6.035 0 0 0-8.306 0 .28.28 0 0 0-.003.396l.983 1.008a.262.262 0 0 0 .369.005 4.145 4.145 0 0 1 2.803-1.097Zm1.929 2.134a.314.314 0 0 1-.078.215l-1.663 1.875a.247.247 0 0 1-.185.086.245.245 0 0 1-.184-.086l-1.663-1.875a.31.31 0 0 1-.079-.215.308.308 0 0 1 .087-.21c1.062-1.004 2.617-1.004 3.679 0a.31.31 0 0 1 .086.21Zm-14.203-6.444c0-.483-.365-.875-.815-.875h-.815c-.45 0-.815.392-.815.875v7.59c0 .483.365.875.815.875h.815c.45 0 .815-.392.815-.876v-7.589Zm-5.68.993h.815c.45 0 .815.401.815.896v5.68c0 .495-.365.896-.815.896h-.815c-.45 0-.814-.401-.814-.897v-5.678c0-.496.364-.897.814-.897Zm-3.309 2.024h-.815c-.45 0-.815.406-.815.908v3.632c0 .502.365.908.815.908h.815c.45 0 .815-.407.815-.908v-3.632c0-.502-.365-.908-.815-.908Zm-4.049 1.868h-.815c-.45 0-.815.4-.815.895v1.79c0 .494.365.895.815.895h.815c.45 0 .815-.4.815-.895v-1.79c0-.495-.365-.895-.815-.895Z"
        clipRule="evenodd"
      />
      <motion.image
        initial={{ y: '100%' }}
        animate={{ y: drawerOpen ? 0 : '100%' }}
        transition={{
          duration: 1,
          ease: 'easeInOut',
        }}
        href="/misc/drawer.png"
        y="230"
        x="10"
        width="302"
        height="438"
      />
      <rect width={26} height={33} x={150} y={79} fill="#C6CDF5" rx={13} />
      <rect
        width={180}
        height={30}
        x={73.47}
        y={165.422}
        fill="#F0F1F7"
        rx={8}
      />
      <rect
        width={69}
        height={30}
        x={128.97}
        y={201.157}
        fill="#F0F1F7"
        rx={8}
      />
      <rect
        width={79.904}
        height={19.952}
        x={123.518}
        y={134}
        fill="#EFF68D"
        rx={5.735}
      />
    </g>
    <defs>
      <filter
        id="phone-mock-d"
        width={350.865}
        height={454.747}
        x={-11.933}
        y={226.355}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={-4.576} />
        <feGaussianBlur stdDeviation={5.339} />
        <feColorMatrix values="0 0 0 0 0.196078 0 0 0 0 0.298039 0 0 0 0 0.858824 0 0 0 0.02 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1695_6292"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={-19.068} />
        <feGaussianBlur stdDeviation={9.534} />
        <feColorMatrix values="0 0 0 0 0.196078 0 0 0 0 0.298039 0 0 0 0 0.858824 0 0 0 0.02 0" />
        <feBlend
          in2="effect1_dropShadow_1695_6292"
          result="effect2_dropShadow_1695_6292"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={-42.712} />
        <feGaussianBlur stdDeviation={12.966} />
        <feColorMatrix values="0 0 0 0 0.196078 0 0 0 0 0.298039 0 0 0 0 0.858824 0 0 0 0.01 0" />
        <feBlend
          in2="effect2_dropShadow_1695_6292"
          result="effect3_dropShadow_1695_6292"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect3_dropShadow_1695_6292"
          result="shape"
        />
      </filter>
      <filter
        id="phone-mock-g"
        width={284.495}
        height={64.069}
        x={21.252}
        y={398.177}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.763} />
        <feGaussianBlur stdDeviation={0.763} />
        <feColorMatrix values="0 0 0 0 0.278431 0 0 0 0 0.262745 0 0 0 0 0.772549 0 0 0 0.04 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1695_6292"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={2.288} />
        <feGaussianBlur stdDeviation={1.144} />
        <feColorMatrix values="0 0 0 0 0.278431 0 0 0 0 0.262745 0 0 0 0 0.772549 0 0 0 0.04 0" />
        <feBlend
          in2="effect1_dropShadow_1695_6292"
          result="effect2_dropShadow_1695_6292"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={6.102} />
        <feGaussianBlur stdDeviation={1.907} />
        <feColorMatrix values="0 0 0 0 0.278431 0 0 0 0 0.262745 0 0 0 0 0.772549 0 0 0 0.02 0" />
        <feBlend
          in2="effect2_dropShadow_1695_6292"
          result="effect3_dropShadow_1695_6292"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={10.678} />
        <feGaussianBlur stdDeviation={2.288} />
        <feColorMatrix values="0 0 0 0 0.278431 0 0 0 0 0.262745 0 0 0 0 0.772549 0 0 0 0.01 0" />
        <feBlend
          in2="effect3_dropShadow_1695_6292"
          result="effect4_dropShadow_1695_6292"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect4_dropShadow_1695_6292"
          result="shape"
        />
      </filter>
      <filter
        id="phone-mock-h"
        width={284.495}
        height={64.069}
        x={21.252}
        y={458.432}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.763} />
        <feGaussianBlur stdDeviation={0.763} />
        <feColorMatrix values="0 0 0 0 0.278431 0 0 0 0 0.262745 0 0 0 0 0.772549 0 0 0 0.04 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1695_6292"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={2.288} />
        <feGaussianBlur stdDeviation={1.144} />
        <feColorMatrix values="0 0 0 0 0.278431 0 0 0 0 0.262745 0 0 0 0 0.772549 0 0 0 0.04 0" />
        <feBlend
          in2="effect1_dropShadow_1695_6292"
          result="effect2_dropShadow_1695_6292"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={6.102} />
        <feGaussianBlur stdDeviation={1.907} />
        <feColorMatrix values="0 0 0 0 0.278431 0 0 0 0 0.262745 0 0 0 0 0.772549 0 0 0 0.02 0" />
        <feBlend
          in2="effect2_dropShadow_1695_6292"
          result="effect3_dropShadow_1695_6292"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={10.678} />
        <feGaussianBlur stdDeviation={2.288} />
        <feColorMatrix values="0 0 0 0 0.278431 0 0 0 0 0.262745 0 0 0 0 0.772549 0 0 0 0.01 0" />
        <feBlend
          in2="effect3_dropShadow_1695_6292"
          result="effect4_dropShadow_1695_6292"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect4_dropShadow_1695_6292"
          result="shape"
        />
      </filter>
      <filter
        id="phone-mock-i"
        width={284.495}
        height={64.069}
        x={21.252}
        y={518.687}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.763} />
        <feGaussianBlur stdDeviation={0.763} />
        <feColorMatrix values="0 0 0 0 0.278431 0 0 0 0 0.262745 0 0 0 0 0.772549 0 0 0 0.04 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1695_6292"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={2.288} />
        <feGaussianBlur stdDeviation={1.144} />
        <feColorMatrix values="0 0 0 0 0.278431 0 0 0 0 0.262745 0 0 0 0 0.772549 0 0 0 0.04 0" />
        <feBlend
          in2="effect1_dropShadow_1695_6292"
          result="effect2_dropShadow_1695_6292"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={6.102} />
        <feGaussianBlur stdDeviation={1.907} />
        <feColorMatrix values="0 0 0 0 0.278431 0 0 0 0 0.262745 0 0 0 0 0.772549 0 0 0 0.02 0" />
        <feBlend
          in2="effect2_dropShadow_1695_6292"
          result="effect3_dropShadow_1695_6292"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={10.678} />
        <feGaussianBlur stdDeviation={2.288} />
        <feColorMatrix values="0 0 0 0 0.278431 0 0 0 0 0.262745 0 0 0 0 0.772549 0 0 0 0.01 0" />
        <feBlend
          in2="effect3_dropShadow_1695_6292"
          result="effect4_dropShadow_1695_6292"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect4_dropShadow_1695_6292"
          result="shape"
        />
      </filter>
      <linearGradient
        id="phone-mock-a"
        x1={0}
        x2={325.743}
        y1={0}
        y2={676.152}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#CDD5FF" />
      </linearGradient>
      <linearGradient
        id="phone-mock-c"
        x1={163}
        x2={163}
        y1={44.5}
        y2={624}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDFDFF" stopOpacity={0} />
        <stop offset={1} stopColor="#F0F2FC" />
      </linearGradient>
      <linearGradient
        id="phone-mock-e"
        x1={163.5}
        x2={240.819}
        y1={295}
        y2={1488.98}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFDFE" stopOpacity={0} />
        <stop offset={1} stopColor="#FCFDFE" />
      </linearGradient>
      <clipPath id="phone-mock-b">
        <rect
          width={300.418}
          height={650.506}
          x={12.758}
          y={12.758}
          fill="#fff"
          rx={40.824}
        />
      </clipPath>
    </defs>
  </svg>
)

export default PhoneMock
