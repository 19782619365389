'use client'
import { ArrowRight } from 'lucide-react'

import { Text } from '../Text'

import { cn } from '@/lib/utils'

export interface MenuNavButtonProps {
  textMain: string
  textSub?: string
  active?: boolean
  className?: string
  onClick?: () => void
}

export const MenuNavButton = ({
  textMain,
  textSub,
  active,
  className,
  onClick,
}: MenuNavButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        'flex flex-col md:flex-row rounded-2xl gap-4 md:gap-8 text-left p-8 pr-11.5 outline-none ring-1 cursor-pointer ring-baby-blue hover:ring-1 hover:ring-blue focus-visible:ring-1 focus-visible:ring-blue focus:ring-1 focus:ring-blue transition duration-300',
        {
          'bg-white': !active,
          'bg-baby-blue': active,
        },
        className,
      )}
    >
      <Text
        variant="tiny-feature"
        as="span"
        className="text-trueBlue flex-1 flex-shrink-0"
      >
        {textMain}
      </Text>
      <Text
        variant="pSmall"
        as="span"
        className="flex-1 flex-shrink-0 text-gray-4"
      >
        {textSub}
      </Text>
      <div className="flex justify-end flex-[0.3] flex-shrink-0">
        <ArrowRight
          width={undefined}
          height={undefined}
          className="w-6 flex-shrink-0"
        />
      </div>
    </button>
  )
}

export default MenuNavButton
