import { groq } from 'next-sanity'
import { Slug } from 'sanity'

import { NewsLinkList } from '@/components/NewsLinkList'
import { SectionInView } from '@/components/SectionInView'
import { Text } from '@/components/Text'
import { cn } from '@/lib/utils'
import { client } from '@/sanity/lib/client'
import { linkResolver } from '@/sanity/lib/linkResolver'
import {
  NewsOverviewSection as NewsOverviewSectionType,
  Tag,
} from '@/types/sanity.types'
import { SectionProps } from '@/types/sections'

export type NewsOverviewCard = {
  title?: string
  date?: string
  tag?: Tag
  slug?: Slug
}

type PickedNewsOverviewSection = Pick<NewsOverviewSectionType, 'heading'>
type ResponseType = PickedNewsOverviewSection & {
  overview: {
    heading?: string
    items?: NewsOverviewSectionType['items']
  }
  cards: NewsOverviewCard[]
}

export const mapNewsOverviewCardsData = (data: NewsOverviewCard[]) =>
  (data ?? []).map((item) => {
    return {
      children: item.title ?? '',
      url: linkResolver(item.slug, 'news') ?? '',
      tag: item.tag?.name ?? '',
    }
  })

export async function NewsOverviewSection({
  sectionId,
  className,
  preview,
}: SectionProps) {
  const data = await client(preview).fetch<ResponseType>(
    groq`{
      "overview": *[_type == "newsOverviewSection" && _id == $sectionId][0] {
        heading,
        items[]-> {
          title,
          date,
          tag->,
          slug
        }
      },
      "cards": *[_type == "news"][0...6] {
        title,
        date,
        tag->,
        slug
      }
    }`,
    { sectionId },
  )

  const customNewsItems =
    data?.overview?.items &&
    Array.isArray(data.overview.items) &&
    data.overview.items.length > 0

  let items = mapNewsOverviewCardsData(data.cards)

  if (customNewsItems) {
    const picked = (data.overview.items ?? []).filter(
      (x): x is NonNullable<typeof x> => typeof x !== 'undefined',
    ) as NewsOverviewCard[]

    items = mapNewsOverviewCardsData(picked)
  }

  return (
    <SectionInView name="news-overview-section" className={cn('', className)}>
      <div className="container">
        <Text variant="h3" as="h3" className="inline-block w-full mb-10">
          {data?.overview?.heading ?? ''}
        </Text>
        <NewsLinkList customNewsItems items={items} />
      </div>
    </SectionInView>
  )
}
