'use client'

import { useState } from 'react'

import { Button } from '../Button'
import NewsLink, { NewsLinkProps } from '../NewsLink/NewsLink'

import { getNewsItems } from '@/api/news'
import { mapNewsOverviewCardsData } from '@/sanity/sections/NewsOverviewSection/NewsOverviewSection'

export interface NewsLinkListProps {
  customNewsItems?: boolean
  items?: NewsLinkProps[]
}

export const NewsLinkList = ({ customNewsItems, items }: NewsLinkListProps) => {
  const [newsItems, setNewsItems] = useState<NewsLinkProps[]>(items ?? [])
  const [total, setTotal] = useState<number | null>(null)

  if (!items) return null

  const start = newsItems.length
  const end = newsItems.length + 3

  const fetchMore = async () => {
    const results = await getNewsItems({
      start,
      end,
    })

    setTotal(results?.total ?? 0)
    setNewsItems([
      ...newsItems,
      ...mapNewsOverviewCardsData(results?.items ?? []),
    ])
  }

  const showMorebutton = total ? newsItems.length < total : true

  return (
    <>
      <div className="grid md:grid-flow-row md:grid-cols-2 xl:grid-cols-3 gap-8">
        {newsItems.map(({ ...props }, index) => {
          return <NewsLink key={index} {...props} />
        })}
      </div>
      {!customNewsItems && (
        <div className="flex justify-center items-center min-h-[100px]">
          {showMorebutton && (
            <Button onClick={() => fetchMore()} variant="ghost">
              Sjá fleiri fréttir
            </Button>
          )}
        </div>
      )}
    </>
  )
}

export default NewsLinkList
