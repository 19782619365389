'use client'

import { AnimatePresence, motion } from 'framer-motion'
import { Fragment, useState } from 'react'

import CustomImage, { CustomImageProps } from '../CustomImage/CustomImage'
import { Label } from '../Label'
import MenuNavButton from '../MenuNavButton/MenuNavButton'
import { Text } from '../Text'

export interface ImageOverviewSelectorProps {
  heading: string
  subHeading: string
  tagline: string
  overviewItems: {
    title: string
    description: string
    image?: CustomImageProps
  }[]
}

export const ImageOverviewSelector = ({
  heading,
  subHeading,
  tagline,
  overviewItems,
}: ImageOverviewSelectorProps) => {
  const [active, setActive] = useState(0)

  const activeImage = overviewItems[active].image

  return (
    <div className="w-full flex flex-col gap-21 items-center gutter">
      <div className="flex flex-col items-center max-w-[500px] text-center">
        <Label variant="white" className="mb-4">
          {tagline}
        </Label>
        <Text variant="h3" as="h2" className="mb-6">
          {heading}
        </Text>
        <Text variant="pSmall" className="text-gray-4">
          {subHeading}
        </Text>
      </div>

      <div className="w-full grid grid-cols-12">
        {/* Left */}
        <div className="col-span-12 lg:col-span-6 xl:col-span-6 flex flex-col justify-center gap-5">
          {overviewItems.map((item, index) => (
            <Fragment key={index}>
              <MenuNavButton
                textMain={item.title}
                textSub={item.description}
                className="hidden lg:flex"
                active={active === index}
                onClick={() => setActive(index)}
              />
              {/* This is the not button version of the MenuNavButton for mobile  */}
              <div className="flex lg:hidden flex-col rounded-2xl gap-4 text-left p-8 pr-11.5 ring-1 ring-baby-blue bg-white">
                <Text
                  variant="tiny-feature"
                  as="span"
                  className="text-trueBlue flex-1 flex-shrink-0"
                >
                  {item.title}
                </Text>
                <Text
                  variant="pSmall"
                  as="span"
                  className="flex-1 flex-shrink-0"
                >
                  {item.description}
                </Text>
              </div>
            </Fragment>
          ))}
        </div>
        {/* Right */}
        <div className="col-span-12 lg:col-start-auto lg:col-span-6 xl:col-span-5 xl:col-start-8 mt-16 lg:mt-0 hidden lg:flex items-center">
          {activeImage && (
            <motion.div className="relative w-full aspect-square lg:ml-4">
              {/* TODO: Figure out image srcset when the two columns ratio is right */}
              <AnimatePresence mode="sync">
                <motion.div
                  className="absolute top-0 left-0 w-full h-full origin-bottom-right rounded-16px overflow-hidden"
                  key={`${activeImage.sanityImageData?.file?.asset?._id}-${active}`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <CustomImage {...activeImage} />
                </motion.div>
              </AnimatePresence>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ImageOverviewSelector
