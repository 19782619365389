'use client'

import { motion, useMotionValueEvent, useScroll } from 'framer-motion'
import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'

import { Label } from '../Label'
import { Text } from '../Text'

export type ScrollItem = {
  label?: string
  heading: string
  text: string
  image: string
}

export interface ScrollFeatureProps {
  items: ScrollItem[]
}

export const ScrollFeature = ({ items }: ScrollFeatureProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const imageContainerRef = useRef<HTMLDivElement>(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const { height: windowHeight } = useWindowSize()
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start center', 'end center'],
  })
  const [allVisible, setAllVisible] = useState<boolean | null>(null)

  useMotionValueEvent(scrollYProgress, 'change', (value) => {
    if (items && items.length) {
      const percentageEach = 100 / items.length - 1
      const progress = Math.round(value * 100)
      const currentIndex = Math.floor(progress / percentageEach)

      setCurrentIndex(
        currentIndex < items.length ? currentIndex : items.length - 1,
      )
    }
  })

  useEffect(() => {
    if (!imageContainerRef.current) {
      return
    }

    let animationFrame: number | undefined

    const resize = () => {
      !allVisible &&
        requestAnimationFrame(() => {
          const height =
            imageContainerRef.current?.getBoundingClientRect().height ?? 0
          if (imageContainerRef.current?.style) {
            imageContainerRef.current.style.top = `${Math.max(
              (window.innerHeight - height) / 2,
              0,
            )}px`
          }
        })
    }

    resize()

    window.addEventListener('resize', resize)

    return () => {
      animationFrame && cancelAnimationFrame(animationFrame)
      window.removeEventListener('resize', resize)
    }
  }, [allVisible])

  useEffect(() => {
    if (windowHeight && containerRef.current) {
      setAllVisible(
        windowHeight > containerRef.current.getBoundingClientRect().height,
      )
    }
  }, [windowHeight, containerRef])

  if (!items || !items.length) return null

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: allVisible !== null ? 1 : 0 }}
      ref={containerRef}
      className="container"
    >
      <div className="grid grid-cols-12">
        <div className="col-span-12 lg:col-start-auto lg:col-span-5 xl:col-span-4 xl:col-start-2">
          {items.map(({ image, ...props }, index) => {
            return (
              <div key={index} className="mt-32 lg:mt-0">
                <div className="lg:h-[50svh] lg:max-h-[1200px] lg:min-h-[1000px] flex flex-row items-center gutter pb-12 lg:pb-0 lg:px-6">
                  <TextItems {...props} active={currentIndex === index} />
                </div>
                <div className="block lg:hidden aspect-square rounded-16px overflow-hidden relative mx-12">
                  <Image src={image} layout="fill" objectFit="cover" alt="" />
                </div>
              </div>
            )
          })}
        </div>
        <div className="invisible lg:visible static col-span-12 col-start-auto lg:col-span-6 lg:col-start-7 xl:col-start-7 xl:col-span-5 mb-12">
          {allVisible === true ? (
            items.map(({ image }, index) => {
              return (
                <div
                  key={index}
                  className="lg:h-[50svh] lg:max-h-[1200px] lg:min-h-[1000px] flex flex-row items-center gutter pb-12 lg:pb-0 lg:px-6"
                >
                  <div className="relative aspect-square w-full rounded-16px overflow-hidden">
                    <Image src={image} layout="fill" objectFit="cover" alt="" />
                  </div>
                </div>
              )
            })
          ) : (
            <div
              ref={imageContainerRef}
              className="sticky top-0 aspect-square w-full"
            >
              {items.map(({ image }, index) => {
                return (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: currentIndex >= index ? 1 : 0,
                    }}
                    exit={{ opacity: 0 }}
                    className="absolute aspect-square w-full rounded-16px overflow-hidden"
                  >
                    <Image src={image} layout="fill" objectFit="cover" alt="" />
                  </motion.div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  )
}

const TextItems = ({
  label,
  heading,
  text,
  active,
}: Omit<ScrollItem, 'image'> & { active: boolean }) => {
  return (
    <motion.div
      initial={{ y: -12 }}
      animate={{ y: active ? -12 : 12 }}
      exit={{ y: -12 }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
      }}
      className="space-y-8 w-full"
    >
      {label && <Label>{label}</Label>}
      <Text variant="h3" as="h3">
        {heading}
      </Text>
      <Text
        variant="p"
        as="p"
        className="text-gray-4 inline-block max-w-[400px]"
      >
        {text}
      </Text>
    </motion.div>
  )
}

export default ScrollFeature
