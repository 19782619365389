'use client'

import { AnimatePresence, motion } from 'framer-motion'
import { Check, Copy } from 'lucide-react'
import { ReactNode, useState } from 'react'
import { useCopyToClipboard } from 'react-use'

import { employees } from './mock'
import { CustomImage } from '../CustomImage'
import { CustomImageProps } from '../CustomImage/CustomImage'
import { Text } from '../Text'

import { cn, itemsByGroup } from '@/lib/utils'

export type EmployeeItem = {
  group?: string
  fullName: string
  role?: string
  email?: string
  image?: CustomImageProps
}

export interface EmployeesProps {
  heading?: string
  heading2?: string
  text?: string
  logoContent?: ReactNode
  items?: EmployeeItem[]
  labelCopyEmail?: string
  labelEmailWasCopied?: string
}

export const Employees = ({
  heading,
  heading2,
  text,
  logoContent,
  items = employees,
  labelCopyEmail = 'Afrita netfang',
  labelEmailWasCopied = 'Netfang afritað',
}: EmployeesProps) => {
  const [_, copyToClipboard] = useCopyToClipboard()
  const [copiedIndex, setCopiedIndex] = useState<string | null>(null)
  const [activeIndex, setActiveIndex] = useState<string | null>(null)

  const onClick = (index: string, email?: string) => {
    email && copyToClipboard(email)
    email && setCopiedIndex(index)
  }

  const listItems = Object.entries(itemsByGroup<EmployeeItem>(items ?? []))

  return (
    <div className="container border-t-gray-2 border-t pt-16">
      <div className="grid grid-cols-12 gap-6">
        <div
          className={cn(
            'col-span-12 md:col-span-4 lg:col-span-4 xl:col-span-6',
            {
              'mb-24': !!heading2,
            },
          )}
        >
          <div className="text-blue mb-10">
            <Text variant="cardTitle" as="h3">
              {heading}
            </Text>
          </div>
          {text && (
            <div className="text-gray-4 mt-4 font-light">
              <Text variant="p" as="span">
                {text}
              </Text>
              {logoContent && logoContent}
            </div>
          )}
        </div>
        <div className="col-span-12 md:col-span-8 lg:col-span-8 xl:col-span-6">
          {heading2 && (
            <div className="text-blue mb-10">
              <Text variant="cardTitle" as="h3">
                {heading2}
              </Text>
            </div>
          )}
          {listItems.map(([group, items], index) => {
            return (
              <div key={index} className="mb-16">
                {group !== 'DEFAULT' ? (
                  <div className="text-gray-3 font-bold">
                    <Text
                      as="span"
                      variant="label-small-bold"
                      className="text-gray-3 font-bold"
                    >
                      {group}
                    </Text>
                  </div>
                ) : null}
                {(items ?? [])?.map((item, subIndex) => {
                  const idx = `${index}-${subIndex}`
                  const isActiveIndex = activeIndex === idx
                  const isEmailCopied = copiedIndex === idx

                  return (
                    <EmployeesItem
                      key={idx}
                      item={item}
                      itemIndex={idx}
                      isActiveIndex={isActiveIndex}
                      isEmailCopied={isEmailCopied}
                      setActiveIndex={setActiveIndex}
                      onClick={onClick}
                      labelCopyEmail={labelCopyEmail}
                      labelEmailWasCopied={labelEmailWasCopied}
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Employees

type EmployeesItemProps = {
  item: EmployeeItem
  itemIndex: string | null
  isActiveIndex?: boolean
  isEmailCopied?: boolean
  setActiveIndex: (index: string | null) => void
  onClick: (index: string, email?: string) => void
} & Pick<EmployeesProps, 'labelCopyEmail' | 'labelEmailWasCopied'>

const EmployeesItem = ({
  item: { fullName, role, email, image },
  itemIndex,
  isActiveIndex,
  labelCopyEmail,
  labelEmailWasCopied,
  isEmailCopied,
  setActiveIndex,
  onClick,
}: EmployeesItemProps) => {
  return (
    <div className="relative -mr-4 pr-4">
      <AnimatePresence>
        {image?.sanityImageData?.file && isActiveIndex && (
          <motion.div
            initial={{ opacity: 0, y: 20, x: -172 - 16 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{
              duration: 0.4,
              ease: 'easeInOut',
            }}
            className="invisible absolute aspect-square w-[172px] rounded-8px overflow-hidden pointer-events-none top-4 md:visible"
          >
            <CustomImage {...image} />
          </motion.div>
        )}
      </AnimatePresence>
      <button
        onMouseEnter={() => setActiveIndex(itemIndex)}
        onMouseLeave={() => setActiveIndex(null)}
        onFocus={() => setActiveIndex(itemIndex)}
        onBlur={() => setActiveIndex(null)}
        className="grid grid-cols-12 py-4 w-full focusable gap-1 md:gap-4"
        onClick={() => {
          itemIndex && onClick(itemIndex, email)
        }}
      >
        <div className="col-span-12 md:col-span-6 h-full">
          <div className="inline-flex w-full items-center space-x-4 text-blue h-full">
            <Text
              variant="label-medium"
              as="span"
              className="inline-block text-left"
            >
              {fullName}
            </Text>
          </div>
        </div>
        <div className="col-span-6 md:col-span-3 text-gray-3 h-full">
          <div className="flex h-full min-h-6 items-center">
            <Text variant="label-small" className="text-left">
              {role}
            </Text>
          </div>
        </div>
        <div className="col-span-6 md:col-span-3 text-gray-3 flex justify-end h-full">
          <AnimatePresence>
            {email && isActiveIndex && (
              <motion.div
                initial={{ opacity: 0, x: -12 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 12 }}
                transition={{
                  duration: 0.6,
                  ease: 'easeInOut',
                }}
                className="flex h-full items-center gap-4 justify-end whitespace-nowrap overflow-visible"
              >
                {!isEmailCopied && (
                  <Text variant="label-small">{labelCopyEmail}</Text>
                )}
                {isEmailCopied && (
                  <Text variant="label-small">{labelEmailWasCopied}</Text>
                )}
                <div className="w-6 h-6 flex items-center justify-center bg-blue rounded-full text-white">
                  {isEmailCopied ? <Check width={16} /> : <Copy width={16} />}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </button>
    </div>
  )
}
